export const environment = {
  firebase: {
    apiKey: "AIzaSyBWpMb9-IlrjkRe0t0tQu3cQ0xPjYj_5Yo",
    authDomain: "staging.frc.nexus",
    databaseURL: "https://frc-virtual-queue-staging-default-rtdb.firebaseio.com",
    projectId: "frc-virtual-queue-staging",
    storageBucket: "frc-virtual-queue-staging.appspot.com",
    messagingSenderId: "874281191270",
    appId: "1:874281191270:web:edecc76f37eb25d3ce6aa7",
    measurementId: "G-45PMZMGZRP"
  },
  production: true,
  useEmulatedFunctions: false,
  emulatedHandoutsUrl: false,
  emulatedSignsUrl: false,
  hostUrl: 'https://staging.frc.nexus',
  slackClientId: '3810479091186.4154573173825',
  csaSlackClientId: '3810479091186.4753211754963',
  discordClientId: '1109270028882288660',
};
