import { Injectable,inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { Database, objectVal, ref } from '@angular/fire/database';
import {Observable,of,switchMap,map, shareReplay, combineLatest} from 'rxjs';
import { EventDataService } from 'nexus-core';

@Injectable({
  providedIn: 'root'
})
export class AuthzService {
  private readonly db = inject(Database);
  private readonly auth = inject(Auth);
  private readonly eventDataService = inject(EventDataService);
  
  roles$ = this.eventDataService.getRoleDefinitions(true).pipe(shareReplay(1));

  isAuthorized(eventKey: string, permission: string): Observable<boolean> {
    return user(this.auth).pipe(
      switchMap(user => {
        if (user) {
          return objectVal(ref(this.db, `events/${eventKey}/authz/${user.uid}/${permission}`)).pipe(switchMap(async hasAuth => {
            if (hasAuth) return true;
    
            const tokenResult = await user.getIdTokenResult();
            if (tokenResult?.claims['superAdmin']) return true;
    
            return false;
          }));
        }
        return of(false);
      }),
    );
  }

  getHighestRole(eventKey: string): Observable<string|undefined> {
    return combineLatest([user(this.auth), this.roles$]).pipe(
      switchMap(([user, roles]) => {
        if (user) {
          return objectVal<{[permission: string]: boolean}>(ref(this.db, `events/${eventKey}/authz/${user.uid}`)).pipe(switchMap(async authz => {
            if (!authz) return undefined;

            for(const role of Object.values(roles).sort((a,b) => a.sort - b.sort)) {
              if(role.permissions.every(val => authz[val])) {
                return role.title;
              }
            }
            return 'Unknown role';
          }));
        }

        return of(undefined);
      }),
    );
  }

  isSuperAdmin(): Observable<boolean> {
    return user(this.auth).pipe(
      switchMap(user => user ? user.getIdTokenResult() : of(undefined)),
      map(tokenResult => !!tokenResult?.claims['superAdmin']));
  }
}
